/* TODO Add site wide styles */
@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes expandHeight {
  from {
    height: 0;
  }

  to {
    height: 100px;
  }
}

/* Set background color to transparent for autofill */
.parameter-input:-webkit-autofill,
.parameter-input:-webkit-autofill:hover,
.parameter-input:-webkit-autofill:focus {
  /* background-color: transparent !important; */
  color: #ffffff !important;
  /* Maintain the input field's text color */
  transition: background-color 5000s ease-in-out 0s;
}

/* Adjust the first line color, just in case */
.parameter-input:-webkit-autofill::first-line {
  color: #ffffff !important;
}

/* ::-webkit-scrollbar {
  width: 0;
} */

/* width */
.styled-scrollbar::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 6px;
  margin: 10px;
  display: scroll;
}

/* Track */
.styled-scrollbar::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
  border-radius: 10px;
  margin-right: 40px;
}

/* Handle */
.styled-scrollbar::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
  background: rgb(203, 200, 200);
  border-radius: 10px;
}

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}
